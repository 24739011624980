import React from 'react';
import Layout from '../../components/CleanLayout';
import SigninController from '../../components/login/SigninController.container';
import Signin from '../../components/login/Signin.container';
import SigninSwitch from '../../components/login/SigninSwitch';
import SignoutButton from '../../components/login/SignoutButton';
import InternationalPET from '../../components/graphing/PET/InternationalPET';
import stylesModule from '../../components/graphing/PET/graphStyles.module.scss';

const METADATA = [
  {
    name: 'description',
    content: 'International PET market',
  },
  {
    name: 'keywords',
    content: 'Subtle Medical, PET, international',
  },
];

const Graph = () => (
  <Layout pageTitle="International PET Market" metadata={METADATA}>
    <SigninController />
    <SignoutButton />
    <h1 className="admin--title">International PET Market</h1>
    <Signin />
    <SigninSwitch>
      <InternationalPET styles={stylesModule}>
        <div>Legend</div>
        <div>Circle Size: Number of PET Scanners</div>
      </InternationalPET>
    </SigninSwitch>
  </Layout>
);

export default Graph;
