import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
// import styles from './graphStyles.module.scss';

import DATA from './pet.json';
import META from './metadata.json';
import useThrottleResize from '../../../hooks/useThrottleResize';
import useEventListener from '../../../hooks/useEventListener';
import useDimensions, { getDimensions } from '../../../hooks/useDimensions';

const CHART_MARGIN = 30;
const ORDINALS = ["None", "Very low", "Low", "Moderate", "High", "Very high"];
const priceToOrdinal = d3.scaleQuantile().domain([300, 1800]).range(ORDINALS.slice(1));
const VALUES = [0, 1, 2, 3, 4, 5];

/**
 * X-axis: scan volume
 * Y-axis: avg scanner utilization (scans / machine)
 * cirlce size: number of scanners
 * circle color: gradient range -- cost per scan
 * other ideas: outer circle dashed lines to represent growth potential of market
 */

function calculateGraphScale(width, height) {
  console.log('Calculating Graph dimensions');
  console.log(width, height)
  const PROPS = {
    x: "Avg charge / reimbursement (USD)",
    y: "Volume / scanner",
    z: "PET Units",
    // z: "PET Volume",
    c: "Wait time (range)"
  };

  // x-axis: reimbursement / scan
  // y-axis: scans / machine
  // z-axis: scan volume
  // color: wait times

  const {
    ["PET Volume"]: volume,
    ["Volume / scanner"]: volumePerScanner,
    ["PET Units"]: scannerCount,
    ["Avg charge / reimbursement (USD)"]: reimbursement,
  } = META;
  // const { min: xMin, max: xMax } = volume;
  // const { min: yMin, max: yMax } = volumePerScanner;
  const { max: zMax } = scannerCount;

  // const x = d3.scalePow().domain([0, 500000, 2100000])
  //   .range([CHART_MARGIN * 2, (width - CHART_MARGIN) * 0.85, (width - 2 * CHART_MARGIN)]).exponent(0.5);
  // const z = d3.scalePow().domain([0, 1945000]).range([5, 100]).exponent(0.75);

  const x = d3.scaleLinear().domain([0, 2200])
    .range([CHART_MARGIN * 2, (width - 2 * CHART_MARGIN)]);
  const y = d3.scaleLinear().domain([5000, 0])
    .range([CHART_MARGIN, height - 2 * CHART_MARGIN]);
  const z = d3.scalePow().domain([0, zMax]).range([5, 80]).exponent(0.5);
  const c = d3.scaleOrdinal().domain(ORDINALS.slice(1)).range(['#03910A', '#8EDA01', '#FFDF06', '#FF7D18', '#FF3A24']);

  // draw and style axes
  const xAxis = d3.axisBottom(x)
    .tickValues(d3.range(0, 2201, 200))
    .tickSizeOuter(0)
    .tickFormat(d => `$${d3.format(',')(d)}`);
  // .tickFormat(d3.format(''))

  const yAxis = d3.axisLeft(y)
    .tickValues(d3.range(500, 5001, 500))
    .tickSizeOuter(0)
    .tickSizeInner(3)
    .tickPadding(2);

  return {
    x,
    y,
    z,
    c,
    xAxis,
    yAxis,
    PROPS,
  };
}

function useDrawGraph(width, height, xLabel, yLabel, styles) {
  const { x, y, z, c, xAxis, yAxis, PROPS } = calculateGraphScale(width, height);


  useEffect(() => {
    const nodes = DATA.map(el => {
      const node = {};
      Object.assign(node, el);
      node.x = x(el[PROPS.x]);
      node.y = y(el[PROPS.y]);
      node.radius = z(el[PROPS.z]);
      node.color = c(el[PROPS.c]);
      return node;
    });

    // legend
    // const legendStart = Math.max(width / 4)
    // const breakpoints = d3.scaleQuantiles(c);
    // breakpoints.unshift(breakpoints[0] - (breakpoints[1] - breakpoints[0])); // add first quantile to start of legend
    // const legendRange = d3.range(breakpoints.length).map(el => {
    //   return legendStart + (width - 2 * legendStart) / breakpoints.length * el;
    // });
    // const legendScale = c.copy().range(legendRange);

    // const legend = d3.select('#legend').selectAll("rect").data(breakpoints);
    // legend.enter().append("rect")
    //   .attr("fill", d => c(d)).attr("height", LEGEND_HEIGHT + "px")
    //   .merge(legend)
    //   .attr("x", d => legendScale(d)).attr("y", height - CHART_MARGIN / 2)
    //   .attr("width", 5);

    // const axisLegend = d3.axisBottom(legendScale)
    //   .tickValues(breakpoints)
    // .tickFormat(formatLegendTicks)

    // const xLegend = selectAxis(d3.select("#legend"), "xLegend", "edu")
    //   .attr("transform", `translate(0,${height - CHART_MARGIN / 2 + 5})`)
    //   .call(axisLegend)

    // add tickpoint at end of legend
    // addFinalTick("#xLegend", legendScale, breakpoints, formatLegendTicks)

    // prevent overlapss
    d3.forceSimulation(nodes)
      .force('collision', d3.forceCollide()
        .radius(d => d.radius))
      .on('tick', renderChart);

    function renderChart() {
      // append and select all nodes
      const root = d3.select('svg');
      const tooltip = d3.select('#tooltip');

      root.select('#data-points').selectAll('circle')
        .data(nodes)
        .enter()
        .append('circle')
        .on('mouseover', d => {
          tooltip.style('left', `${d3.event.pageX + 15}px`)
            .style('top', `${d3.event.pageY - 30}px`)
            .html(tooltipText(d));
          tooltip.transition().duration(500).style('opacity', 1);
        })
        .on('mouseout', () => {
          tooltip.style('left', '-500px').style('top', '-500px').style('opacity', 0);
        });

      // draw and style nodes
      const points = root.selectAll('circle');
      points.attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('r', d => d.radius)
        .attr('fill', '#0aaf80')

      // add country labels
      root.select('#data-points').selectAll('text')
        .data(nodes).enter()
        .append('text')
        .attr('class', styles.countryLabel)
        .attr('id', d => `label-${d.Country}`)
        .attr('width', d => d.radius)
        .text(d => d.Country);

      root.select('#data-points').selectAll('text')
        .attr('transform', d => {
          const offset = document.getElementById(`label-${d.Country}`)
            .getBoundingClientRect().width / 2;
          return `translate(${-offset},0)`;
        })
        .attr('x', d => d.x)
        .attr('y', d => d.y + 6);

      function tooltipText(d) {
        return `${d.Country}<br />
        PET Volume: ${d3.format(',')(d['PET Volume'])}<br />
        PET Scanners: ${d["PET Units"]}<br />
        Volume / million ppl: ${d3.format(',')(d['Volume / million ppl'])}<br />
        Wait time: ${d['Wait time (range)']}<br />
        Reimbursements: ${priceToOrdinal(d['Avg charge / reimbursement (USD)'])} ($${d['Avg charge / reimbursement (USD)']})
        `;
      }
    }


    // create axes
    d3.select('#x-axis')
      .attr('transform', `translate(0, ${height - 2 * CHART_MARGIN})`)
      .text('Annual Scan Volume')
      .call(xAxis);

    d3.select('#y-axis')
      .attr('x', CHART_MARGIN)
      .attr('y', CHART_MARGIN)
      .attr('transform', `translate(${CHART_MARGIN * 2}, 0)`)
      .text('Scans per PET Machine')
      .call(yAxis);

    // labels
    const xL = d3.select('#x-label')
      .text(xLabel)
      .attr('y', height - CHART_MARGIN * 2.5);

    const yL = d3.select('#y-label')
      .text(yLabel)
      .attr('y', CHART_MARGIN * -2.5)
      .attr('transform', 'rotate(90)');

    // adjust offset (have to be in dom before this can be calculated)
    const { width: xLabelWidth } = document.getElementById('x-label').getBoundingClientRect();
    const { height: yLabelLength } = document.getElementById('y-label').getBoundingClientRect();
    xL.attr('x', width * 3 / 4); // - xLabelWidth / 2
    yL.attr('x', height / 4 - yLabelLength / 2);


  });
}

// add country labels
// tooltip shouldn't overflow screen
// dollar sign to x-axis
// legend for colors


export default function InternationalPET({ children, styles }) {
  const SCALE = { x: 1, y: 0.8 };
  const MIN_CHART_WIDTH = 500;
  const [INITIAL_WIDTH, INITIAL_HEIGHT] = getDimensions(1000, 800, 5, 0);
  // state for SVG size
  const [width, setWidth] = useState(INITIAL_WIDTH);
  const [height, setHeight] = useState(INITIAL_HEIGHT);

  if (typeof window !== 'undefined') {
    useThrottleResize();
    useEventListener('throttledResize', () => useDimensions(setWidth, setHeight, { xOffset: 5 }));
  }

  const widthOrMinWidth = Math.max(MIN_CHART_WIDTH, width * SCALE.x);
  useDrawGraph(widthOrMinWidth, height * SCALE.y, 'Avg. Reimbursement', 'Volume per Scanner', styles);

  // console.log(data);
  return (
    <div
      id="graph"
      className={styles.graphContainer}
    >
      <div id="tooltip" className={styles.tooltip} />
      <svg height={height * SCALE.y} width={widthOrMinWidth} className={styles.canvas}>
        <g id="data-points" />
        <text id="x-label" className={styles.label} />
        <text id="y-label" className={styles.label} />
        <g id="x-axis" className={styles.axis} />
        <g id="y-axis" className={styles.axis} />
        <g id="legend" className={styles.legend} />
      </svg>
      <div className={styles.legendContainer}>
        {children}
      </div>
    </div>
  );
}
